import { Helmet } from 'react-helmet';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import loadable from '@loadable/component';

import { ListGroup } from 'react-bootstrap';

import { BoxArrowUpRight } from 'react-bootstrap-icons';

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// import FloodMap from '../components/FloodMap.js';
import PlannedRoutes from '../components/PlannedRoutes.js';
import FloodAreas from '../components/FloodAreas.js';
import RiverGauge from '../components/RiverGauge.js';
import Loading from '../components/Loading.js';


import { sendViewByAreaClick, sendViewByRouteClick } from '../components/GoogleAnalytics.js';

import { StationLinkCalculator } from '../lib/LinkCalculators.js';
import { getFloodMapForAreaByName, clearFloodMapActiveArea } from '../store/reducers/fmAPI.js';

import statusInfo from '../lib/statusInfo.js';

// Optimisations
const RoadClosuresComponent = loadable(() => import('../components/RoadClosuresComponent.js'));
const CentralLink = loadable(() => import('../components/CentralLink.js'));
const StationNameRenderer = loadable(() => import('../components/StationNameRenderer.js'));
const FloodMap = loadable(() => import('../components/FloodMap.js'), { ssr: false, fallback: <Loading /> });
let statusMappings = statusInfo.statusMappings;
let statusText = statusInfo.statusText;

function FlodOverviewView() {
	const params = useParams();
 	const { selectedView } = useSelector(state => state.mapViewSelector); 	// Accessing data, loading, and error from api slice
 	const { activeArea } = useSelector(state => state.fmAPI);
 	
 	const [activeViewTab, setActiveViewTab] = useState("AREA_VIEW_TAB");


	const dispatch = useDispatch();

	useEffect(() => {
		// dispatch(apiCall());
		console.log(params);
		if(window) {
			let expectedMap = params.floodNavName ? params.floodNavName : window.location.hostname.split('.')[0];

			dispatch(getFloodMapForAreaByName(expectedMap, true));
		}

		return () => {
			dispatch(clearFloodMapActiveArea());
		};
	}, [dispatch]);

	if (!activeArea) {//loading || !data) {
		return <Loading />;
	}

	let displayStation = null;
	if(activeArea && activeArea.baseStations) {

		// Primary id is by STATION id, Base Stations are by Mapping Id
		let mappingKeys = Object.keys(activeArea.baseStations);
		let chosenStation = null;
		for(let mappingKey of mappingKeys) {
			let potentialStation = activeArea.baseStations[mappingKey];
			if(activeArea.primaryStation === potentialStation.metricId) {
				chosenStation = activeArea.baseStations[mappingKey];
				break;
			}
		}
		if(!chosenStation) {
			if(mappingKeys.length > 0) {
				chosenStation = activeArea.baseStations[mappingKeys[0]];
			}
		}
		if(!chosenStation) {
			console.error("Cannot select a station... giving up")
		} else {
			displayStation = chosenStation;
		}
	}

	// if(displayStation === null) {
	// 	return <div>Not able to show river data for the station on this floodnav. Please report this error.</div>;
	// }

	let classForButtonRowDefaults = "bg-secondary text-white";
	let classForButtonRowActive = "bg-dark text-white";
	let classForButtonRow = {
		"areaViewTab": classForButtonRowDefaults,
		"routeViewTab": classForButtonRowDefaults
	};
	if(activeViewTab === "AREA_VIEW_TAB") {
		classForButtonRow.areaViewTab = classForButtonRowActive;
	} else {
		classForButtonRow.routeViewTab = classForButtonRowActive;
	}

	let defaultCoords = [53.9590, -1.0815];
	if(activeArea && activeArea.mapDetails && activeArea.mapDetails.lat && activeArea.mapDetails.lng) {
		defaultCoords = [activeArea.mapDetails.lat, activeArea.mapDetails.lng]
	}

	let computedPageTitle = "FloodNav for " + activeArea.locationName;
	try {
		if(activeArea.locationName[activeArea.locationName.length-1] === 's') {
			computedPageTitle = activeArea.locationName + "' FloodNav";
		} else {
			computedPageTitle = activeArea.locationName + "'s FloodNav";
		}
	} catch(error) {}
	 
	const hasFlooding = !!(Object.keys(activeArea.mapFloodAreas).find(function(areaKey) {
		let area = activeArea.mapFloodAreas[areaKey];
		return (area.status === "IN_FLOOD" || area.status === "PARTIALLY_FLOODED");
	}))

	return (<div>
		<Helmet>
			<title>Is {activeArea.locationName} flooded? View {computedPageTitle}</title>
		</Helmet>
		{activeArea.locationName && <Row>
			<Col xs={12} className="bg-dark text-white"><h1 className="h1-floodnav">{computedPageTitle}</h1></Col>
			<Col xs={12} className="bg-dark text-white"><h2 className="h2-floodnav">Is {activeArea.locationName} flooded?</h2></Col>
		</Row>}
		
		<Row>
			<Col lg={1} xxl={2} className="d-none d-lg-block" style={{borderRight: "1px solid black"}}>&nbsp;</Col>
			<Col xs={12} lg={10} xxl={8}>
				<Row>
					<Col className="g-0" style={{minHeight: "50vh", width: "100%"}}>
						<FloodMap
							basePath={params.floodNavName ? '/floodnav/' + params.floodNavName : ""}
							defaultCoords={defaultCoords}
							mapFloodAreas={activeArea.mapFloodAreas}
							mapFloodDefences={activeArea.mapFloodDefences}
							mapBridges={activeArea.mapBridges}
							roadClosures={activeArea.roadClosures}
							statusMappings={statusMappings}
							selectedView={selectedView}
							defaultZoom={activeArea.mapDetails.defaultZoom}
							mapName={activeArea.locationName}
							mapAttractions={activeArea.mapAttractions}
						/>
					</Col>
				</Row>
				<Row>
					{false && <Col xs={12} className="bg-warning"><b>Flooding Warnings in Effect</b></Col>}
					{false && <Col xs={12} md={4} className="bg-dark text-white"><b>View {displayStation.eaStationLabel || "Station"} Details</b></Col>}
					<Col xs={12} md={6}>
						{displayStation && <RiverGauge title="Current" readingTime={displayStation.entryTimestampFormatted} entryHeight={displayStation.entryHeight}/>}
					</Col>
					<Col xs={12} md={6}>{displayStation && displayStation.forecastMax && <RiverGauge title="Forecast" readingTime={displayStation.forecastMaxTimestampFormatted} entryHeight={displayStation.forecastMax}/>}</Col>
				</Row>
			</Col>
			<Col lg={1} xxl={2} className="d-none d-lg-block" style={{borderLeft: "1px solid black"}}>&nbsp;</Col>
		</Row>
		<Row style={{borderBottom: "1px solid black"}}>
			<Col lg={1} xxl={2} className="d-none d-lg-block" style={{borderRight: "1px solid black"}}>&nbsp;</Col>
			<Col xs={12} lg={10} xxl={8}>
				<Row style={{paddingBottom: "4px"}}>
					<Col xs={0} md={0} className="d-none"></Col>
					<Col xs={6} md={6} className={classForButtonRow.areaViewTab} style={{cursor: "pointer"}} onClick={function() {
						setActiveViewTab("AREA_VIEW_TAB");
						sendViewByAreaClick(activeArea.locationName);
					}}><b className="cursor-pointer">View by Area</b></Col>
					<Col xs={6} md={6} className={classForButtonRow.routeViewTab} style={{cursor: "pointer"}} onClick={function() {
						setActiveViewTab("ROUTE_VIEW_TAB");
						sendViewByRouteClick(activeArea.locationName);
					}}><b className="cursor-pointer">View by Route</b></Col>
				</Row>
				{displayStation && <Row>
					<Col xs={0} md={0} className="d-none"></Col>
					<Col xs={12} md={12}>
						{activeViewTab === "ROUTE_VIEW_TAB" && <PlannedRoutes
							basePath={params.floodNavName ? '/floodnav/' + params.floodNavName : ""}
							mapFloodAreas={activeArea.mapFloodAreas}
							baseStations={activeArea.baseStations}
							routes={activeArea.mapBridges}
							statusMappings={statusMappings}
							forecastHeight={parseFloat(displayStation.forecastMax)}
							statusText={statusText}
							displayStation={displayStation}
							plannedRouteType="BRIDGE"
							mapName={activeArea.locationName}
						/>}
						{activeViewTab === "ROUTE_VIEW_TAB" && <PlannedRoutes
							basePath={params.floodNavName ? '/floodnav/' + params.floodNavName : ""}
							mapFloodAreas={activeArea.mapFloodAreas}
							baseStations={activeArea.baseStations}
							routes={activeArea.mapEscapeRoutes}
							statusMappings={statusMappings}
							forecastHeight={parseFloat(displayStation.forecastMax)}
							statusText={statusText}
							displayStation={displayStation}
							plannedRouteType="ESCAPE"
							mapName={activeArea.locationName}
						/>}
						{(activeViewTab === "ROUTE_VIEW_TAB" && Object.keys(activeArea.mapEscapeRoutes).length+Object.keys(activeArea.mapBridges).length === 0) ? <span>No routes for this location</span> : null}
						{activeViewTab === "AREA_VIEW_TAB" && <FloodAreas
							basePath={params.floodNavName ? '/floodnav/' + params.floodNavName : ""}
							mapFloodAreas={activeArea.mapFloodAreas}
							baseStations={activeArea.baseStations}
							statusMappings={statusMappings}
							forecastHeight={parseFloat(displayStation.forecastMax)}
							statusText={statusText}
							mapFloodDefences={activeArea.mapFloodDefences}
							displayStation={displayStation}
							mapName={activeArea.locationName}
							mapAttractions={activeArea.mapAttractions}
						/>}
					</Col>
				</Row>}
			</Col>
			<Col lg={1} xxl={2} className="d-none d-lg-block" style={{borderLeft: "1px solid black"}}>&nbsp;</Col>
			<Col lg={1} xxl={2} className="d-none d-lg-block" style={{borderRight: "1px solid black"}}>&nbsp;</Col>
			<Col xs={12} md={10} lg={10} xxl={8}>
				{!displayStation && <Row>
						<Col xs={12} className="text-white" style={{backgroundColor: "#dd2222"}}><b>Whoops...</b></Col>
						<Col xs={12}><b>No measurement station is mapped to this floodnav</b></Col>
					</Row>}
				{activeArea.roadClosures && activeArea.roadClosures.length > 0 && <RoadClosuresComponent roadClosures={activeArea.roadClosures} />}
				<hr className="d-lg-none" />
				<Row>
					<Col xs={12} className="bg-dark text-white"><b>FloodNav Technical Information</b></Col>
					<Col xs={12} style={{borderBottom: "1px dashed black"}}><h3 className="hX-blendin header-bold">This is a live flood map of {activeArea.locationName}.</h3></Col>
					{activeArea.locationBlurb && <Col xs={12} style={{fontSize: "0.8em", borderBottom: "1px dashed black"}}>
						<i>{activeArea.locationBlurb}</i>
					</Col>}
					<Col xs={6} style={{"textAlign": "right"}}><strong>Is {activeArea.locationName} flooded?</strong> :</Col>
					<Col xs={6}>
						{hasFlooding ? <span><strong>YES</strong></span> : <span><strong>NO</strong></span>}
					</Col>
					<Col xs={6} style={{"textAlign": "right"}}><b>Total Stations</b> :</Col>
					<Col xs={6}>
						{!!activeArea.baseStations ? Object.keys(activeArea.baseStations).length : "0"}
					</Col>
					<Col xs={6} style={{"textAlign": "right"}}><b>Primary Station</b> :</Col>
					<Col xs={6}>
						{displayStation && <StationNameRenderer stationName={displayStation.stationName} qualifierType={displayStation.qualifier} />}
						{!displayStation && <span>None/Not Known</span>}
					</Col>
					{activeArea.baseStations && Object.keys(activeArea.baseStations).reduce(function(acca, stationKey) {
						let station = activeArea.baseStations[stationKey];
						// Short circuit to exclude primary from here
						if(displayStation.metricId === station.metricId && displayStation.qualifier === station.qualifier) {
							return acca;
						}
						acca.push(<Col xs={6} key={"title_"+station.stationName} style={{"textAlign": "right"}}><b>Station</b> :</Col>);
						acca.push(<Col xs={6} key={"stn_"+station.stationName}><StationNameRenderer stationName={station.stationName} qualifierType={station.qualifier} /></Col>);
						return acca;
					}, [])}
				</Row>
				<Row>
					<Col xs={12} className="bg-dark text-white"><b>{activeArea.locationName} Quick Links</b></Col>
					<Col xs={12} className="">
						<Row>
							<Col xs={12} sm={6}>
								<ListGroup>
							      <ListGroup.Item variant="dark" className="bg-dark text-white">
							        Stations used by this FloodNav
							      </ListGroup.Item>
								 {activeArea.baseStations && Object.keys(activeArea.baseStations).map(function(stationKey) {
								 	let station = activeArea.baseStations[stationKey];
								 	return <CentralLink to={StationLinkCalculator(station.stationId, station.qualifier)}>
								 		<ListGroup.Item>
								 			<StationNameRenderer stationName={station.stationName} qualifierType={station.qualifier} />
								 		</ListGroup.Item>
							 		</CentralLink>;
								 })}
							    </ListGroup>
							</Col>
							<Col xs={12} sm={6}>
							    <ListGroup>
							    	<ListGroup.Item variant="dark" className="bg-dark text-white">
							    		FloodMapperUK Resources
							    	</ListGroup.Item>
							    	<CentralLink to={"/floodnav/how-to"}>
								      <ListGroup.Item>
								        How to use FloodNav
								      </ListGroup.Item>
							     	</CentralLink>
							    	{activeArea.pageLinks && activeArea.pageLinks.length > 0 && <ListGroup.Item variant="dark" className="bg-dark text-white">
							    		External Resources
							    	</ListGroup.Item>}
							    	{activeArea.pageLinks && activeArea.pageLinks.length > 0 && activeArea.pageLinks.map(function(link) {
							    		return <a key={link.linkText} target="_blank" rel="noreferrer" href={link.linkUrl}>
							    			<ListGroup.Item>
							    				{link.linkText}{" "}<sup><BoxArrowUpRight /></sup>
							    			</ListGroup.Item>
							    		</a>;
							    	})}
							    </ListGroup>
							</Col>
						</Row>
					</Col>
					<Col xs={12} style={{paddingTop: "20px"}} className="d-md-none">

					</Col>
				</Row>
			</Col>
			<Col lg={1} xxl={2} className="d-none d-lg-block" style={{borderLeft: "1px solid black"}}>&nbsp;</Col>
		</Row>
	</div>);
}
/*
<ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-fb+5w+4e-db+86"
     data-ad-client="ca-pub-6790224688210339"
     data-ad-slot="6130981811"></ins>
<AdSense.Google
				  client='ca-pub-6790224688210339'
				  slot='6130981811'
				  style={{ display: 'block' }}
				  format='fluid'
				  layoutKey='-fb+5w+4e-db+86'
				/>


     */

export default FlodOverviewView;